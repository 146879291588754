import React from "react"
import { StationActivity } from "./models/station-activity";
import StationUserTileInner from "./station-user-tile-inner";

interface IFIPTileProps {
      user: StationActivity;
     // currentWork: any;
//removeFromColumn:  (stationId: number, frameSerialId: string) => void;
}


// Wait for the slide entrance to complete before exiting
/*
const SlideInAndFadeout = () => {
  const [didEnter, setDidEnter] = React.useState(false)
  const onDidEnter = () => setDidEnter(true)
  return (
    <FadeOut active={didEnter}>
      <SlideInDown onComplete={onDidEnter}>🥧</SlideInDown>
    </FadeOut>
  )
}
*/
const StationUserTile = (props: IFIPTileProps) => {


      return (
            <>
            { props.user && 

                        <StationUserTileInner key={props.user.userId} userName={props.user.userName} stationId={props.user.stationId} activity={props.user.activity} userId={props.user.userId} startTime={props.user.startTime} />               

            }

            </>
      );



}

/*
 <Slide active={true} iterations={1}  durationMs={4000}  fade={true} direction={ "down"} >
                        <StationUserTileInner key={props.user.userId} userName={props.user.userName} stationId={props.user.stationId} activity={props.user.activity} userId={props.user.userId} startTime={props.user.startTime} />               
                  </Slide>
                  */

export default StationUserTile;