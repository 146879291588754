

import React from "react";
import Config from "./config";

const PendingTicketsBanner = ( ) =>  {
         const [ pendingTickets, setPendingTickets] = React.useState<number | null>( null);
      
      React.useEffect(() => {
            function pollPendingTickets() {
         
                        const url =  `${Config.baseUrl}/api/shipstation/ticket/unassigned-to-cutting`;
                        try {
                              fetch(url).then(response => response.json())
                              .then(data => {
                                    if (data.unassignedTicketCount) {
                                          setPendingTickets( data.unassignedTicketCount )

                                    } else{
                                          console.error("Failed poll lookup of pending tickets");
                                    }
                  
                              });;                       
                        } catch (error ) {
                              console.error(error);
                        }                  
            }
            pollPendingTickets();
            setInterval(() => {
                  pollPendingTickets();
            }, 60000);

      }, []);

      return (
                  <div className="pending-tickets-banner" > 
                         { pendingTickets && 

                             <div className="pending-tickets-text">{pendingTickets} Pending Tickets to be assigned</div>
                         }
                  </div>
      )
}

export default PendingTicketsBanner;